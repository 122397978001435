.DashBlocksComponent {
  &Header {
    display: grid;

    grid-template-columns: 384px 1fr 1fr 1fr;
    column-gap: 20px;

    margin-bottom: 20px;

    .DashBlock {
      height: 104px;

      text-align: left;
    }

    h6 {
      margin: 0;
      margin-bottom: 16px;

      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.29px;
      text-transform: uppercase;

      color: var(--main-color-shaded);
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.4;

      small {
        font-size: 19px;
      }
    }
  }

  &MainHeader {
    display: grid;

    margin-bottom: 1px;

    grid-template-columns: 384px 1fr;
    column-gap: 20px;

    .DashBlock {
      height: 33px;

      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      padding: 0 19px !important;

      font-size: 12px;
      text-transform: uppercase;

      color: var(--main-color-shaded);
    }
  }

  &Main {
    display: grid;

    grid-template-columns: 384px 1fr;

    column-gap: 20px;
    row-gap: 20px;

    .DashBlock {
      height: 130px;
    }
  }

  .AddField {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--main-color-shaded);
    
    img {
      margin-right: 20px;
      opacity: 0.5;
    }

  }

  .DashBlock {
    background-color: var(--main-bg-color);
    border-radius: 8.4px;

    padding: 22px;

    &First {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &Flex {
      display: flex;

      align-items: center;
      justify-content: space-between;

      &Data {
        padding: 16px 46px !important;
      }
    }

    &.active {
      background-color: var(--secondary-bg);
    }

    &.danger {
      background-color: var(--danger-bg);
    }

    &DataFlex {
      display: flex;
      height: 100%;

      align-items: center;
      justify-content: space-between;

      padding: 0 46px;
    }

    &Inner {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 56px;

      border-radius: 5px;

      text-align: center;

      &Start {
        width: 134px;
        margin-top: 16px;

        background-color: var(--app-bg-color);
      }

      &Data {
        width: 93px;

        font-size: 20px;
        line-height: 1.05;
        letter-spacing: -0.48px;

        color: var(--main-color-shaded-light);
        background-color: var(--light-bg);
      }

      &.active {
        color: var(--green);
        background-color: var(--primary-bg) !important;
      }

      &.danger {
        color: var(--red);
        background-color: var(--danger-bg)  !important;
      }
    }
  }
}
