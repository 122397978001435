.DashboardPage {
  margin: 0 30px;

  height: 100%;

  display: grid;
  grid-template-areas:
    "header header"
    "content side";

  grid-template-columns: auto 106px;
  grid-template-rows: 74px auto;

  column-gap: 28px;

  &Header {
    position: relative;
    grid-area: header;

    height: 74px;

    .Notify {
      position: absolute;
      display: flex;

      top: 0;
      right: 136px;

      width: 680px;
      height: 54px;

      border-radius: 8px;

      align-items: center;
      justify-content: center;

      background-color: var(--red-bg);
    }

    .Nav {
      list-style: none;
      margin: 0;
      padding: 0;

      width: 250px;
      height: 100%;

      display: flex;
      align-items: center;

      justify-content: space-between;

      li {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;

        display: flex;
        align-items: center;

        .active {
          color: var(--green);
        }
      }
    }
  }

  &Grid {
    grid-area: content;
  }

  &Sidebar {
    grid-area: side;

    width: 106px;
  }
}
