.List {
  list-style: none;
  padding: 0;
  margin: 0;

  user-select: none;

  li {
    display: flex;
    align-items: center;

    padding: 0;
    margin: 0;

    height: 56px;

    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.38px;

    border-bottom: 1px solid var(--hr-bg);

    &:last-child {
      border-bottom: none;
    }

    .EndSlot {
      display: flex;
      align-items: center;

      img {
        margin-left: 20px;
      }
    }

    .ListButton {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 56px;
      padding: 0;

      background: transparent;
      border: none;

      color: var(--main-colors);

      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.38px;
    }
  }
}
