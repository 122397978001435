.Modal {
  position: absolute;
  display: none;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  z-index: 5000;

  &Backdrop {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: var(--modal-bg-color);

    z-index: 5000;
  }

  &Body {
    width: 50%;
    // min-height: 50%;

    padding: 40px;

    background-color: var(--main-bg-color);
    border-radius: var(--border-radius);

    z-index: 5001;

    h1 {
      position: relative;
      display: flex;

      align-items: center;

      margin-top: 0;
      margin-bottom: 20px;

      font-size: 20px;

      .closeBtn {
        display: inline-block;
        margin-right: 20px;
      } 

      .actionButton {
        position: absolute;
        right: 0;
      }
    }
  }

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
