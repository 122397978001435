.TextInput {
  display: block;

  width: 100%;
  height: 56px;
  border-radius: 28px;
  border: solid 1px #979797;

  font-size: 16px;

  color: white;
  background: transparent;

  margin-bottom: 30px;

  padding: 8px 30px;
}

.RangeInput {
  width: 100%;
}

.ScrollForm {
  max-height: 400px;
  overflow: scroll;
}