.FieldComponent {
  h4 {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.38px;
  }

  .actionButton {
    text-transform: uppercase;
    font-size: 14px;

    color: var(--link-color);
  }
}
