.Btn {
  border-radius: 28px;

  padding: 15px 40px;

  background-color: var(--blue);
  border: none;

  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-transform: uppercase;

  color: white;

  &Clear {
    background: none;

    color: var(--blue);
  }

  &Block {
    width: 100%;
  }

  &:disabled {
    background-color: #2e2e2e;
  }
}

.closeBtn {
  font-size: 30px;
}