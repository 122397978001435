.ConnetionsComponent {
  position: absolute;
  display: none;

  grid-template-columns: 140px auto;
  column-gap: 100px;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  padding: 62px 115px;

  z-index: 5000;

  opacity: 0.8;
  background-color: var(--app-bg-color);

  text-align: left;

  &.active {
    display: grid;
  }

  img {
    margin-top: 10px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 7px;

    font-size: 34px;
    line-height: 1.65;
  }

  h3 {
    margin-bottom: 68px;

    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.27;
  }

  ol {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    padding-inline-start: 20px;

    li {
      margin-bottom: 24px;
      padding-left: 36px;
    }
  }

  .Buttons {
    margin-top: 44px;

    .BtnClear {
      margin-left: 22px;
    }
  }
}
