.StatsPage {
  padding: 0 72px;

  text-align: left;

  h1 {
    position: relative;

    margin-top: 30px;
    margin-bottom: 40px;

    font-size: 20px;
    line-height: 1;
    

    img {
      position: absolute;

      top: 3px;
      left: -40px;
    }
  }

  .StatsBlockWrapper {
    display: grid;
    grid-template-columns: 187px 187px auto;
    column-gap: 16px;

    margin-bottom: 16px;
  }

  .StatsBlock {
    padding: 16px;
    border-radius: 8px;
    background-color: var(--main-bg-color);

    h6 {
      margin: 0;
      margin-bottom: 8px;

      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.29px;
      text-transform: uppercase;

      color: var(--main-color-shaded);
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.4;

      &.light {
        padding-top: 5px;
        opacity: 0.8;
        font-size: 18px;
        letter-spacing: -0.43px;
      }

      small {
        font-size: 19px;
      }
    }
  }

  .StatsTableWrapper {
    border-radius: 8px;

    padding: 34px 24px;
    padding-bottom: 10px;
    
    background-color: var(--main-bg-color);

    table {
      width: 100%;

      border-collapse: collapse;
      table-layout: fixed;
    }

    th {
      font-size: 12px;
      letter-spacing: -0.29px;
      
      text-transform: uppercase;

      color: var(--main-color-shaded);

      padding-bottom: 23px;
      
      border-bottom: 1px solid var(--main-color-shaded);
    }

    td {
      padding: 20px 0;

      font-size: 20px;
    }
  }
}