@import "../node_modules/roboto-fontface/css/roboto/roboto-fontface.css";

@import "styles/variables.scss";
@import "styles/buttons.scss";
@import "styles/forms.scss";
@import "styles/modal.scss";
@import "styles/list.scss";

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--app-bg-color);
  color: var(--main-color);

  font-size: var(--base-font-size);
}

html, body, #root, .App {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

* {
  box-sizing: border-box;
  outline: none;
}

hr {
  border-top: 0;
  border-color: var(--hr-bg);
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.pullRight {
  float: right;
}

.dimmed {
  opacity: 0.5;
}

