:root {
  --app-bg-color: #000000;
  --main-bg-color: #171717;
  
  --link-color: #2677ff;
  
  --modal-bg-color: rgba(0, 0, 0, 0.8);

  --primary-bg: rgb(23, 32, 19);
  --secondary-bg: rgb(14, 28, 28);
  --danger-bg: rgb(42, 22, 23);
  --light-bg: rgb(30, 30, 30);
  --red-bg:  rgb(59, 17, 18);

  --hr-bg: rgb(41, 41, 41);
  

  --main-color: #FFFFFF;
  --main-color-shaded: #666666;
  --main-color-shaded-light: #999999;

  --green: #51bd21;
  --red: #b02b2c;
  --blue: #2677ff;

  --base-font-size: 14px;
  --border-radius: 8px;
}

