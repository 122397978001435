.TankComponent {
  .TankData {
    display: flex;
    text-align: center;

    &Block {
      margin: 30px 0;
      width: 50%;

      &:first-child {
        border-right: 1px solid var(--hr-bg);
      }

      h6 {
        margin: 0;

        font-size: 14px;
        font-weight: normal;
        line-height: 2.36;
        letter-spacing: -0.34px;

        opacity: 0.5;
      }

      h4 {
        margin: 0;

        font-size: 28px;
        font-weight: normal;
        line-height: 0.79;
        letter-spacing: -0.67px;
      }
    }
  }

  .TankChart {
    height: 72px;
    margin-bottom: 30px;

    border-radius: var(--border-radius);
    background-color: rgb(27, 41, 42);

    overflow: hidden;

    &Block {
      height: 72px;
      background-color: rgb(47, 62, 62);
    }
  }

  .TankText {
    margin: 30px 0;
  }
}
