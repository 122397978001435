.SidebarComponent {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;

  &Btn {
    width: 106px;
    height: 106px;

    border-radius: 50%;
    border: none;

    color: var(--main-color);
    background-color: var(--main-color-shaded);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
