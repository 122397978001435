.FieldCompleteComponent {
  
  .JobTitle {
    font-size: 20px;
  }

  .tableWrapper {
    border-radius: var(--border-radius);
    border: solid 1px var(--hr-bg);

    overflow: hidden;
  }

  .table {
    width: 100%;

    border-collapse: collapse;
    table-layout: fixed;

    text-align: left;

    th {
      padding: 12px;
      font-size: 12px;
      text-transform: uppercase;

      color: var(--main-color-shaded);
      border-bottom: 1px solid var(--hr-bg);
    }

    td {
      padding: 12px;

      font-size: 16px;
    }
  }
}
